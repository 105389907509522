<template>
  <div class="contactus">
    <nav-menu :active="key"></nav-menu>
    <div
      class="contact-us"
      id="contact">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="contact-us-content">
              <div class="row">
                <div class="col-lg-4">
                  <div id="map"></div>
                </div>
                <div class="col-lg-8">
                  <el-form
                    ref="form"
                    :model="form"
                    :rules="rules"
                    id="contact-form">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="section-heading">
                          <h2>
                            <span><em>联系我们</em></span>
                          </h2>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <el-form-item
                          label=""
                          prop="name">
                          <el-input
                            placeholder="您的姓名"
                            v-model="form.name" />
                        </el-form-item>
                      </div>
                      <!-- <div class="col-lg-6">
                         <el-form-item label="" prop="">
                          <el-input
                            type="surname"
                            name="surname"
                            id="surname"
                            placeholder="您的电话"
                            autocomplete="on"
                            required />
                       </el-form-item>
                      </div> -->
                      <div class="col-lg-6">
                        <el-form-item
                          label=""
                          prop="email">
                          <el-input
                            placeholder="您的邮箱"
                            v-model="form.email" />
                        </el-form-item>
                      </div>
                      <div class="col-lg-12">
                        <el-form-item
                          label=""
                          prop="subject">
                          <el-input
                            v-model="form.subject"
                            placeholder="主题" />
                        </el-form-item>
                      </div>
                      <div class="col-lg-12">
                        <el-form-item
                          label=""
                          prop="content">
                          <el-input
                            type="textarea"
                            placeholder="请输入内容"
                            v-model="form.content">
                          </el-input>
                        </el-form-item>
                      </div>
                      <div class="col-lg-12">
                        <el-button
                          class="orange-button"
                          @click="handelSend">
                          发送
                        </el-button>
                      </div>
                    </div>
                  </el-form>
                  <div class="more-info">
                    <div class="row">
                      <!-- <div class="col-lg-4">
                        <div class="info-item">
                          <i class="fa fa-phone"></i>
                          <h4><a href="#">010-020-0340</a></h4>
                        </div>
                      </div> -->
                      <div class="col-lg-12">
                        <div class="info-item">
                          <i class="fa fa-envelope"></i>
                          <h4><a href="#">service@hzbc.cc</a></h4>
                        </div>
                      </div>
                      <!-- <div class="col-lg-6">
                        <div class="info-item">
                          <i class="fa fa-map-marker"></i>
                          <h4>
                            <a href="#"
                              >浙江省杭州市西湖区紫霞街155号西溪诚品商务中心</a
                            >
                          </h4>
                        </div>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <site-footer></site-footer>
  </div>
</template>

<script>
import SiteFooter from '@/components/Footer.vue';
import navMenu from '@/components/NavMenu';
import { contactusAdd } from '@/api/user';
import { isEmail } from '@/assets/js/validate';

export default {
  name: 'ContactUs',
  components: {
    SiteFooter,
    navMenu,
    // carousel,
  },
  computed: {
    key() {
      return this.$route.path;
    },
  },
  data() {
    const validateEmail = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入邮箱'));
      } else if (!isEmail(value)) {
        callback(new Error('请输入正确格式的邮箱'));
      } else {
        callback();
      }
    };
    return {
      form: {
        name: '',
        email: '',
        subject: '',
        content: '',
      },
      rules: {
        email: [{ required: true, validator: validateEmail, trigger: 'blur' }],
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        subject: [{ required: true, message: '请输入主题', trigger: 'blur' }],
        content: [{ required: true, message: '请输入内容', trigger: 'blur' }],
      },
    };
  },
  methods: {
    handelSend() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          contactusAdd(this.form).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.message);
              this.form = {
                name: '',
                email: '',
                subject: '',
                content: '',
              };
            }
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.contact-us {
  padding: 50px 0;
}
#map {
  width: 100%;
  height: 100%;
  background: url('~@/assets/images/left-infos.jpg') no-repeat top center;
  background-size: cover;
}
</style>
