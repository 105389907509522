<template>
  <div class="about">
    <nav-menu></nav-menu>
    <div class="page-heading">
      <div class="container">
        <div class="row">
          <div class="col-lg-7 align-self-center">
            <div class="caption header-text">
              <h6>SEO DIGITAL AGENCY</h6>
              <div class="line-dec"></div>
              <h4>Discover More <em>About Us</em></h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                doers eiusmod tempor incididunt ut labore et dolore.
              </p>
              <div class="main-button"><a href="#">Discover More</a></div>
              <span>or</span>
              <div class="second-button"><a href="#">Check our FAQs</a></div>
            </div>
          </div>
          <div class="col-lg-5 align-self-center">
            <img
              src="assets/images/about-us-image.jpg"
              alt="" />
          </div>
        </div>
      </div>
    </div>

    <div class="video-info section">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="video-thumb">
              <img
                src="assets/images/video-thumb.jpg"
                alt="" />
              <a
                href="http://youtube.com"
                target="_blank"
                ><i class="fa fa-play"></i
              ></a>
            </div>
          </div>
          <div class="col-lg-6 align-self-center">
            <div class="section-heading">
              <h2>Detailed Information On What We Do &amp; Who We Are</h2>
              <div class="line-dec"></div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                doers eiusmod tempor incididunt ut labore et dolore dolor.
              </p>
            </div>
            <div class="skills">
              <div class="skill-slide marketing">
                <div class="fill"></div>
                <h6>SEO Marketing</h6>
                <span>90%</span>
              </div>
              <div class="skill-slide digital">
                <div class="fill"></div>
                <h6>Digital Marketing</h6>
                <span>80%</span>
              </div>
              <div class="skill-slide media">
                <div class="fill"></div>
                <h6>Social Media Management</h6>
                <span>95%</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="happy-clients section">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-heading">
              <h2>
                Our 4 Steps <em>To Success</em> &amp; <span>Happy Clients</span>
              </h2>
              <div class="line-dec"></div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                doers.
              </p>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="naccs">
              <div class="tabs">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="menu">
                      <div class="active">
                        <span>Project Introduction</span>
                      </div>
                      <div><span>Work Development</span></div>
                      <div><span>Data Analysis</span></div>
                      <div class="last-item">
                        <span>Project Finishing</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <ul class="nacc">
                      <li class="active">
                        <div>
                          <div class="row">
                            <div class="col-lg-7">
                              <h4>Best CSS Templates for you</h4>
                              <div class="line-dec"></div>
                              <p>
                                Tale is the best SEO agency website template
                                using Bootstrap v5.2.2 CSS for your company. It
                                is a free download provided by TemplateMo. There
                                are 3 HTML pages, <a href="index.html">Home</a>,
                                <a href="about.html">About</a>, and
                                <a href="faqs.html">FAQ</a>.
                              </p>
                              <div class="info">
                                <span>Website Design</span>
                                <span>User Interface</span>
                                <span>User Experience</span>
                                <span class="last-span">Digital Agency</span>
                              </div>
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed doers eiusmod tempor
                                incididunt ut labore et dolore dolor dolor sit
                                amet, consectetur adipicing elit, sed doers
                                eiusmod.
                              </p>
                            </div>
                            <div class="col-lg-5 align-self-center">
                              <img
                                src="assets/images/happyclient-01.jpg"
                                alt="" />
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div>
                          <div class="row">
                            <div class="col-lg-7">
                              <h4>Detailed Information On What We Do</h4>
                              <div class="line-dec"></div>
                              <p>
                                You are free to use this template for any
                                purpose. You are not allowed to redistribute the
                                downloadable ZIP file of Tale SEO Template on
                                any other template website. Please contact us.
                                Thank you.
                              </p>
                              <div class="info">
                                <span>HTML CSS</span>
                                <span>Bootstrap 5</span>
                                <span>TemplateMo</span>
                                <span class="last-span">Development</span>
                              </div>
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed doers eiusmod tempor
                                incididunt ut labore et dolore dolor dolor sit
                                amet, consectetur adipicing elit, sed doers
                                eiusmod.
                              </p>
                            </div>
                            <div class="col-lg-5 align-self-center">
                              <img
                                src="assets/images/happyclient-01.jpg"
                                alt="" />
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div>
                          <div class="row">
                            <div class="col-lg-7">
                              <h4>Responsive HTML CSS Templates</h4>
                              <div class="line-dec"></div>
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed doers eiusmod kent tempor
                                incididunt ut labore et dolore dolor.
                              </p>
                              <div class="info">
                                <span>SEO Trend</span>
                                <span>Digital Agency</span>
                                <span>Best Template</span>
                                <span class="last-span">Development</span>
                              </div>
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed doers eiusmod tempor
                                incididunt ut labore et dolore dolor dolor sit
                                amet, consectetur adipicing elit, sed doers
                                eiusmod.
                              </p>
                            </div>
                            <div class="col-lg-5 align-self-center">
                              <img
                                src="assets/images/happyclient-01.jpg"
                                alt="" />
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div>
                          <div class="row">
                            <div class="col-lg-7">
                              <h4>Detailed Information about SEO Techniques</h4>
                              <div class="line-dec"></div>
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed doers eiusmod kent tempor
                                incididunt ut labore et dolore dolor.
                              </p>
                              <div class="info">
                                <span>Data Analysis</span>
                                <span>SEO Trend</span>
                                <span>Templates</span>
                                <span class="last-span">Research</span>
                              </div>
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed doers eiusmod tempor
                                incididunt ut labore et dolore dolor dolor sit
                                amet, consectetur adipicing elit, sed doers
                                eiusmod.
                              </p>
                            </div>
                            <div class="col-lg-5 align-self-center">
                              <img
                                src="assets/images/happyclient-01.jpg"
                                alt="" />
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="cta section">
      <div class="container">
        <div class="row">
          <div class="col-lg-8">
            <h4>
              Are You Ready To Work &amp; Develop With Us ?<br />Don't Hesitate
              &amp; Contact Us !
            </h4>
          </div>
          <div class="col-lg-4">
            <div class="main-button">
              <a href="#">Contact Us Now!</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <site-footer></site-footer>
  </div>
</template>

<script>
import SiteFooter from '@/components/Footer.vue';
import navMenu from '@/components/NavMenu';

export default {
  name: 'About',
  components: {
    SiteFooter,
    navMenu,
  },
};
</script>

<style></style>
